<template>
  <v-dialog
    :width="width"
    data-vuetify
    v-model="dialogModel"
    :key="modalKey"
  >
    <v-card
      data-vuetify
      class="pt-5"
    >
      <slot></slot
      ><v-icon
        size="28"
        color="primary"
        style="
          position: absolute !important;
          top: 4px !important;
          right: 4px !important;
        "
        @click="$emit('input', false)"
        >mdi-close-circle-outline</v-icon
      ></v-card
    >
  </v-dialog>
</template>

<script>
export default {
  name: "DefaultModal",
  props: {
    width: {
      type: String,
      required: false,
      default: "500",
    },
    value: {},
  },
  data() {
    return {
      modalKey: 0,
    };
  },
  computed: {
    dialogModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
        this.rerenderModal();
      },
    },
  },
  methods: {
    rerenderModal() {
      this.modalKey++;
    },
  },
};
</script>

<style scoped></style>
