import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import notifications from "./../modules/messages/store/notifications";
import menu from "./../modules/menu/store/menu";
import dashboard from "@/modules/dashboard/store/dashboard";
import insumos from "@/modules/insumos/store/insumos";
import roles from "../modules/roles_vuetify/store/index";
import usuarios from "@/modules/usuarios/store/usuarios";
import sortingBays from "../modules/sorting_bays_vuetify/store";
import panelInspection from "@/modules/panel_inspection/store";
import orderSupervision from "@/modules/order_supervision/store";
import gerenciarPedido from "@/modules/gerenciar_pedido/state/gerenciar_pedido";
import groundMounts from "@/modules/ground_mounts/store";

Vue.use(Vuex);

const isProductionBuild = process.env.NODE_ENV === "production";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["menu", "notifications"],
});

export default new Vuex.Store({
  strict: !isProductionBuild,
  modules: {
    notifications,
    menu,
    dashboard,
    insumos,
    roles,
    usuarios,
    sortingBays,
    panelInspection,
    orderSupervision,
    gerenciarPedido,
    groundMounts,
  },
  plugins: [vuexLocal.plugin],
});
