import { HttpRestService } from "@/services/http/HttpRestService";

export default class PedidoWebRepository {
  static build() {
    return new PedidoWebRepository();
  }
  getPedidosWebList(params) {
    return HttpRestService.get(`/api/v2/pedido-web`, params);
  }
  getPedidosNaoExpedir(params) {
    return HttpRestService.get(`/api/v2/pedido/nao-expedir`, params);
  }
  getPedidoPeloSerial(params) {
    return HttpRestService.get(`/api/v2/pedido/serial/listar`, params);
  }
  getPedidosDiversosList(params) {
    return HttpRestService.get(`/api/v2/pedido-diversos`, params);
  }

  alterarVolume(numeroPedido) {
    return HttpRestService.post(
      `/api/v2/pedido-web/${numeroPedido}/alterar-volume`
    );
  }

  iniciarProducao(numeroPedido) {
    return HttpRestService.post(
      `/api/v2/pedido-web/${numeroPedido}/iniciarProducao`
    );
  }

  agendarManutencao() {
    return HttpRestService.post(`/api/v2/pedido-web/agendamento-manutencao`);
  }

  iniciarConferencia(numeroPedido) {
    return HttpRestService.post(
      `/api/v2/pedido-web/${numeroPedido}/iniciar-conferencia`
    );
  }

  despachar(numeroPedido) {
    return HttpRestService.post(`/api/v2/pedido-web/${numeroPedido}/despachar`);
  }

  cancelarDespacho(numeroPedido) {
    return HttpRestService.post(
      `/api/v2/pedido/${numeroPedido}/cancelar-despacho`
    );
  }

  getDetalhesPedido(numeroPedido) {
    return HttpRestService.get(`/api/v2/pedido-web/${numeroPedido}`);
  }

  getListagemTelaManutencao() {
    return HttpRestService.get(`/api/v2/pedido-web/manutencao`);
  }

  getLinhasPendentes(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/pedido-web/${numeroPedido}/linhas-pendentes`
    );
  }

  criarVolumePerfis(numeroPedido, { sspedidoNumSeqList }) {
    return HttpRestService.post(
      `/api/v2/pedido-web/${numeroPedido}/criar-volume/linha/perfis`,
      { sspedidoNumSeqList, linha: 4 }
    );
  }

  finalizarLinhaPerfis(numeroPedido) {
    return HttpRestService.post(
      `/api/v2/pedido/${numeroPedido}/finaliza/linha/perfis`
    );
  }

  finalizarLinhaCabos(numeroPedido) {
    return HttpRestService.post(
      `/api/v2/pedido/${numeroPedido}/finaliza/linha/cabos`
    );
  }
  //
  // moverInsumoPerfis(numeroPedido, body){
  //   return HttpRestService.post(`/api/v2/pedido-web/${numeroPedido}/mover-insumo/linha/perfis`, body)
  // }

  criarVolumePaineis(numeroPedido, body) {
    return HttpRestService.post(
      `/api/v2/pedido-web/${numeroPedido}/criar-volume/linha/paineis`,
      body
    );
  }

  criarVolumeCabos(numeroPedido, body) {
    return HttpRestService.post(
      `/api/v2/pedido-web/${numeroPedido}/criar-volume/linha/cabos`,
      body
    );
  }

  moverInsumoPaineis(numeroPedido, body) {
    return HttpRestService.post(
      `/api/v2/pedido-web/${numeroPedido}/mover-insumo/linha/paineis`,
      body
    );
  }

  biparInsumoPaineis({ serial, insumoId }) {
    return HttpRestService.post(`/api/v2/bipagem-insumo/paineis`, {
      serial,
      insumoId,
    });
  }

  finalizarLinhaPaineis(numeroPedido) {
    return HttpRestService.post(
      `/api/v2/pedido/${numeroPedido}/finaliza/linha/paineis`
    );
  }

  getListagemLinhaPaineis(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/insumos/pedido/${numeroPedido}/linha/paineis`
    );
  }

  getPedidoResumo(numeroPedido) {
    return HttpRestService.get(`/api/v2/pedido/pedido-resumo/${numeroPedido}`);
  }

  pedidoResumo(numeroPedido) {
    return HttpRestService.get(`/api/v2/pedido/rel-embarque/${numeroPedido}`);
  }

  pedidoResumoEmbarque(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/pedido/resumo-embarque/${numeroPedido}`
    );
  }
  getListagemLinhaDiversos(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/insumos/pedido/${numeroPedido}/linha/diversos`
    );
  }

  criarVolumeDiversos(numeroPedido, body) {
    return HttpRestService.post(
      `/api/v2/pedido-web/${numeroPedido}/criar-volume/linha/diversos`,
      body
    );
  }

  moverInsumoDiversos(numeroPedido) {
    return HttpRestService.get(
      `/pedido-web/${numeroPedido}/mover-insumo/linha/diversos`
    );
  }

  finalizarLinhaDiversos(numeroPedido) {
    return HttpRestService.post(
      `/api/v2/pedido/${numeroPedido}/finaliza/linha/diversos`
    );
  }

  biparInsumoDiversos({ serial, insumoId }) {
    return HttpRestService.post(`/api/v2/bipagem-insumo/diversos`, {
      serial,
      insumoId,
    });
  }

  getListagemLinhaInversores(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/insumos/pedido/${numeroPedido}/linha/inversores`
    );
  }

  criarVolumeInversores(numeroPedido, body) {
    return HttpRestService.post(
      `/api/v2/pedido-web/${numeroPedido}/criar-volume/linha/inversores`,
      body
    );
  }

  moverInsumoInversores(numeroPedido) {
    return HttpRestService.get(
      `/pedido-web/${numeroPedido}/mover-insumo/linha/inversores`
    );
  }

  finalizarLinhaInversores(numeroPedido) {
    return HttpRestService.post(
      `/api/v2/pedido/${numeroPedido}/finaliza/linha/inversores`
    );
  }

  biparInsumoInversores({ serial, insumoId }) {
    return HttpRestService.post(`/api/v2/bipagem-insumo/inversores`, {
      serial,
      insumoId,
    });
  }

  blockOrder(order) {
    return HttpRestService.post(`/api/v2/pedido/bloquear`, order);
  }
}
