import { HttpRestService } from "@/services/http/HttpRestService";
import { HttpFileRequestService } from "@/services/http/HttpFileRequestService";

export default class Repository {
  static getOrders(urlParams, defaultParams) {
    return HttpRestService.get(`/api/v2/pedido-web`, {
      ...urlParams,
      ...defaultParams,
    });
  }
  static getOrderDetails(body) {
    return HttpRestService.get(
      `/api/v2/pedido/detalhe-bloqueio/listar/${body.orderNumber}/${body.bloqueioId}`
    );
  }
  static unblockOrder(body) {
    const send = {
      pedidos: [body.orderId],
      bloqueioId: parseInt(body.bloqueioId),
    };
    return HttpRestService.put(`/api/v2/pedido/desbloquear-pedido`, send);
  }
  static generateOrderReport(body, fileName, fileFormat) {
    return HttpFileRequestService.build()
      .setMethod("POST")
      .setUrl(`/api/v2/bloqueio/relatorio/conferencia-painel`)
      .setBody(body)
      .request(fileFormat, fileName);
  }
}
