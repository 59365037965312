import Repository from "../repository";

export default {
  async orders({ commit, state }) {
    commit("setIsLoadingOrders", true);
    commit("setOrders", {});
    try {
      const response = await Repository.getOrders(
        state.defaultParams,
        state.urlParams
      );
      if (response.status === 200) {
        const orders = response.data.data.list;
        const pagination = response.data.data.pagination;
        commit("setOrders", orders);
        commit("setPagination", pagination);
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response.data.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsLoadingOrders", false);
    }
  },
  async getOrderDetails({ commit }, body) {
    commit("setIsLoadingOrderDetails", true);
    commit("setOrderDetails", {});
    try {
      const response = await Repository.getOrderDetails(body);
      if (response.status === 200) {
        commit(
          "notifications/setSuccessNotification",
          {
            successStatus: true,
            successMessage: "Sucesso ao listar os detalhes do pedido.",
          },
          { root: true }
        );
        const orderDetails = response.data.data[0] || {};
        commit("setOrderDetails", orderDetails);
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response.data.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsLoadingOrderDetails", false);
    }
  },
  async unblockOrder({ commit, dispatch }, body) {
    console.log(body);
    try {
      const response = await Repository.unblockOrder(body);
      if (response.status === 200) {
        commit(
          "notifications/setSuccessNotification",
          { successStatus: true, successMessage: response.data.message },
          { root: true }
        );
        dispatch("getOrderDetails", body);
        dispatch("orders");
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response.data.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    }
  },
  async generateOrderReport({ commit }, { body, fileName, fileFormat }) {
    try {
      const response = await Repository.generateOrderReport(
        body,
        fileName,
        fileFormat
      );
      if (response?.status === 200) {
        commit(
          "notifications/setSuccessNotification",
          {
            successStatus: true,
            successMessage: "Relatório gerado com sucesso",
          },
          { root: true }
        );
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response?.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    }
  },
  setUrlParams({ commit }, payload) {
    commit("setUrlParams", payload);
  },
  setCurrentPageToFirstPage({ commit, state }) {
    commit("setUrlParams", { ...state.urlParams, page: 1 });
  },
};
