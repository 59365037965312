<template>
  <default-table-body-row>
    <v-col cols="12" sm="6" md="2">
      <span class="d-block d-md-none font-weight-bold">Pedido</span>
      <span
        class="d-block text-wrap font-weight-bold secondary--text mb-n2"
        :title="pedido.numeroPedido"
      >
        {{ pedido.numeroPedido }}
      </span>
      <span
        class="text-overline font-weight-light text--text"
        :title="pedido.dataProducao | dateDMY"
      >
        {{ pedido.dataProducao | dateDMY }}
      </span>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <span class="d-block d-md-none font-weight-bold">Cliente</span>
      <span class="d-block text-wrap mb-n1" :title="pedido.clienteNome">
        {{ pedido.clienteNome }}
      </span>
      <span
        class="text-wrap font-weight-light secondary--text text-caption"
        :title="pedido.kitDescricao"
      >
        {{ pedido.kitDescricao }}
      </span>
    </v-col>
    <v-col cols="12" sm="6" :md="isBlockStatusVisible ? '2' : '3'">
      <span class="d-block d-md-none d-lg-none font-weight-bold"
        >Transportadora</span
      >
      <span :title="pedido.transportadoraNome">{{
        pedido.transportadoraNome
      }}</span>
    </v-col>

    <v-col cols="12" sm="6" md="1" v-if="isBlockStatusVisible">
      <span class="d-block d-md-none font-weight-bold">Status</span>
      <block-status :block-status="pedido.bloqueioStatus" />
    </v-col>
    <v-col cols="12" sm="12" md="1">
      <default-fab
        tooltip-text="Ver Detalhes"
        v-if="!isUsuarioLinha3"
        :loading="visualizacaoDetalhesPedido.loading"
        @click="visualizarDetalhesPedido"
        ><v-icon>mdi-text-box-search</v-icon></default-fab
      >
    </v-col>
    <v-col
      cols="12"
      sm="6"
      offset-sm="3"
      md="3"
      offset-md="0"
      v-if="isContinuarConferenciaAc"
    >
      <router-link
        style="text-decoration-line: none"
        :to="{
          name: 'pedido-confere-ac',
          params: { id: pedido.numeroPedido },
        }"
      >
        <default-button @click="continuarConferenciaAc"
          >Continuar Conferência</default-button
        ></router-link
      >
    </v-col>
    <v-col
      cols="12"
      sm="6"
      offset-sm="3"
      md="3"
      offset-md="0"
      v-if="isVerDetalhesDespachados"
    >
      <router-link
        style="text-decoration-line: none"
        :to="{
          name: 'pedido-resumo',
          params: { id: pedido.numeroPedido },
        }"
      >
        <default-button @click="visualizarDetalhesDespachados"
          >Ver Resumo do Pedido</default-button
        ></router-link
      >
    </v-col>
    <v-col
      cols="12"
      sm="6"
      offset-sm="3"
      md="3"
      offset-md="0"
      v-if="isVerDetalhesEmbarque"
    >
      <router-link
        style="text-decoration-line: none"
        :to="{
          name: 'pedido-embarque',
          params: { id: pedido.numeroPedido },
        }"
      >
        <default-button
          @click="visualizarDetalhesEmbarque"
          :loading="isVerDetalhesEmbarque.loading"
        >
          <span>Iniciar Embarque</span>
        </default-button>
      </router-link>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      offset-sm="3"
      md="3"
      offset-md="0"
      class="separate-buttons"
      v-if="
        isGerenciarAdmin ||
        isAguardandoProducao ||
        isLinhaProducao ||
        isLinhaProducaoDiversos ||
        isIniciarConferencia ||
        isContinuarConferencia ||
        isVerDetalhesComProblema
      "
    >
      <default-button v-if="isGerenciarAdmin" @click="gerenciarAdmin()"
        >Gerenciar</default-button
      >
      <default-button
        v-if="isAguardandoProducao"
        :disabled="isButtonDisabled"
        @click="openModalIniciarProducao()"
        >{{
          isButtonDisabled ? "Pedido Bloqueado" : "Iniciar Produção"
        }}</default-button
      >
      <default-button
        v-if="isLinhaProducao"
        @click="continuarProducaoPedido()"
        >{{ "Continuar Produção" }}</default-button
      >
      <default-button
        v-if="isLinhaProducaoDiversos"
        @click="continuarProducaoPedidoDiversos"
        >Continuar Produção</default-button
      >
      <default-button
        v-if="isIniciarConferencia"
        :disabled="isButtonDisabled"
        @click="iniciarConferencia()"
        >{{
          isButtonDisabled ? "Pedido Bloqueado" : "Iniciar Conferência"
        }}</default-button
      >
      <default-button
        v-if="isContinuarConferencia"
        @click="continuarConferencia()"
        :disabled="isButtonDisabled"
        >{{
          isButtonDisabled ? "Pedido Bloqueado" : "Continuar Conferência"
        }}</default-button
      >
      <default-button
        v-if="isVerDetalhesComProblema"
        @click="verDetalhesComProblema()"
        >Ver Detalhes</default-button
      >
      <default-button v-if="isGerenciarAdmin" @click="editarAdmin()"
        >Editar</default-button
      >
    </v-col>
  </default-table-body-row>
</template>

<script>
import BlockStatus from "@/shared/components/vuetify/BlockStatus.vue";
import InsumoPedidoRepository from "@/repositories/v2/InsumoPedidoRepository";
import DefaultButton from "../../../shared/components/vuetify/DefaultButton.vue";
import DefaultFab from "../../../shared/components/vuetify/DefaultFab.vue";
import DefaultTableBodyRow from "../../../shared/components/vuetify/DefaultTableBodyRow.vue";
import VisualizacaoDetalhesPedido from "@/services/listagem_pedidos/VisualizacaoDetalhesPedido";
import ContinuacaoProducaoPedido from "@/services/listagem_pedidos/ContinuacaoProducaoPedido";
import ContinuacaoProducaoPedidoDiversos from "@/services/listagem_pedidos/ContinuacaoProducaoPedidoDiversos";
import PedidoWebRepository from "@/repositories/v2/PedidoWebRepository";
import { UsuarioLogado } from "@/services/login/UsuarioLogado";
import store from "../../../store/store.js";
import router from "@/router";

export default {
  name: "ListingCard",
  created() {
    this.visualizacaoDetalhesPedido = VisualizacaoDetalhesPedido.build()
      .setVm(this)
      .setNumeroPedido(this.pedido.numeroPedido);
    this.continuacaoProducaoPedido = ContinuacaoProducaoPedido.build()
      .setVm(this)
      .setNumeroPedido(this.pedido.numeroPedido);

    this.pedidoWebRepository = PedidoWebRepository.build();
    this.perfil = UsuarioLogado.getPerfil();
  },
  components: {
    DefaultButton,
    DefaultFab,
    DefaultTableBodyRow,
    BlockStatus,
  },

  data: () => ({
    perfilStartModel: ["bipador-painel", "administrador"],
    perfil: [],
    relacaoTiposInsumoQuantidade: [],
    visualizacaoDetalhesPedido: VisualizacaoDetalhesPedido.build(),
    continuacaoProducaoPedido: ContinuacaoProducaoPedido.build(),
    continuacaoProducaoPedidoDiversos:
      ContinuacaoProducaoPedidoDiversos.build(),
    pedidoWebRepository: PedidoWebRepository.build(),
    insumoPedidoRepository: InsumoPedidoRepository.build(),
  }),

  props: {
    pedido: {
      type: Object,
      required: true,
      validator: (pedido) =>
        [
          "numeroPedido",
          "dataProducao",
          "clienteNome",
          "kitDescricao",
          "transportadoraNome",
        ].every((key) => ![null, undefined].includes(pedido[key])),
    },
    colorBtnDetalhes: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    colorBtnIniciarProducao: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
  },
  methods: {
    visualizarDetalhesPedido() {
      // console.group({ pedido: this.pedido });
      // console.log("visualizarDetalhesPedido");
      // console.groupEnd();
      // this.$bvModal.show('modal-detalhes')
      this.visualizacaoDetalhesPedido.abrirDetalhesPedido();
    },

    fecharDetalhesPedido() {
      this.$bvModal.hide("modal-detalhes");
    },
    gerenciarAdmin() {
      console.group({ pedido: this.pedido });
      console.groupEnd();
      this.$router.push({
        name: "gerenciar-pedido",
        params: { id: this.pedido.numeroPedido },
        query: {
          tipoPedido: this.pedido.tipoPedido,
        },
      });
    },
    editarAdmin() {
      this.$router.push({
        name: "editar-pedido",
        params: { numped: this.pedido.numeroPedido },
      });
    },
    continuarConferencia() {
      console.group({ pedido: this.pedido });
      console.groupEnd();
      this.$router.push({
        name: "pedido-confere",
        params: { id: this.pedido.numeroPedido },
      });
    },
    verDetalhesComProblema() {
      console.group({ pedido: this.pedido });
      console.groupEnd();
      this.$router.push({
        name: "pedido-confere-det",
        params: { id: this.pedido.numeroPedido },
      });
    },
    continuarConferenciaAc() {
      console.group({ pedido: this.pedido });
      console.groupEnd();
      this.$router.push({
        name: "pedido-confere-ac",
        params: { id: this.pedido.numeroPedido },
      });
    },
    visualizarDetalhesDespachados() {
      console.group({ pedido: this.pedido });
      console.groupEnd();
      this.$router.push({
        name: "pedido-resumo",
        params: { id: this.pedido.numeroPedido },
      });
    },
    visualizarDetalhesEmbarque() {
      console.group({ pedido: this.pedido });
      console.groupEnd();
      this.$router.push({
        name: "pedido-embarque",
        params: { id: this.pedido.numeroPedido },
      });
    },
    iniciarConferencia() {
      console.group({ pedido: this.pedido });
      console.groupEnd();

      this.pedidoWebRepository
        .iniciarConferencia(this.pedido.numeroPedido)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.$router.push({
              name: "pedido-confere",
              params: { id: this.pedido.numeroPedido },
            });
          } else {
            let errorMessage = {
              errorStatus: true,
              errorMessage:
                response.data.message ??
                "O pedido não tem itens para ser conferidos",
            };
            store.commit("notifications/setErrorNotification", errorMessage, {
              root: true,
            });
            router.push({ name: "pedidosWebAguardandoConferencia" });
          }
        })
        .catch(function (error) {
          console.error(error);
        });

      //this.$router.push({
      //  name: "pedido-confere",
      //  params: { id: this.pedido.numeroPedido },
      //});
    },
    iniciarProducaoPedido() {
      console.group({ pedido: this.pedido });
      console.log("iniciarProducaoPedido");
      console.groupEnd();
    },
    openModalIniciarProducao() {
      this.numeroPedidoModal = this.pedido.numeroPedido;
      // if (this.perfil === "administrador") {
      //   this.$router.replace({
      //     name: "pedidoWebProducaoSelecaoPaineis",
      //     params: {
      //       numped: this.pedido.numeroPedido,
      //     },
      //   });
      //   return;
      // }
      this.$emit(
        "openModalIniciarProducao",
        this.pedido.numeroPedido,
        this.perfil
      );
      // this.$bvModal.show("modal-inicar-producao");
    },
    // openModalIniciarProducao() {
    //   this.$emit(
    //     "openModalIniciarProducao",
    //     this.pedido.numeroPedido,
    //     this.perfil
    //   );
    // },
    async continuarProducaoPedido() {
      this.continuacaoProducaoPedidoDiversos.setTipoPedido(
        this.pedido.tipoPedido
      );
      this.continuacaoProducaoPedido.continuarProducaoPedido();
    },
    continuarProducaoPedidoDiversos() {
      console.group({ pedido: this.pedido });

      this.continuacaoProducaoPedidoDiversos.setNumeroPedido(
        this.pedido.numeroPedido
      );

      this.continuacaoProducaoPedidoDiversos.setTipoPedido(
        this.pedido.tipoPedido
      );

      this.continuacaoProducaoPedidoDiversos.continuarProducaoPedidoDiversos();
    },
  },
  computed: {
    isUsuarioLinha3() {
      return UsuarioLogado.getPermissoes().includes("separacao-da-linha-3");
    },
    status() {
      return this.$parent.pedidosStatus;
    },
    isGerenciarAdmin() {
      return this.status === "";
    },
    isContinuarConferenciaAc() {
      return this.status === "ac";
    },
    isContinuarConferencia() {
      return this.status === "F";
    },
    isVerDetalhesComProblema() {
      return this.status === "T";
    },
    isIniciarConferencia() {
      return this.status === "N";
    },
    isVerDetalhesEmbarque() {
      return this.status === "E";
    },
    isVerDetalhesDespachados() {
      return this.status === "D";
    },
    isAguardandoProducao() {
      return this.status === "A";
    },
    isLinhaProducao() {
      return this.status === "L";
    },
    isLinhaProducaoDiversos() {
      return this.status === "O";
    },
    isButtonDisabled() {
      return (
        this.pedido.bloqueioStatus === "BLOQUEADO" &&
        (this.perfil === "bipador-painel" ||
          this.perfil === "administrador" ||
          this.perfil === "conferente-final")
      );
    },
    isBlockStatusVisible() {
      switch (this.status) {
        case "":
        case "F":
          return true;
        default:
          return false;
      }
    },
  },
};
</script>

<style scoped>
.separate-buttons {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
</style>
