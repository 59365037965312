<template>
  <b-modal data-bv id="modal-etiquetas" ref="modal" title="Gerar Etiquetas">
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        label="Informe o numero do pedido."
        label-for="numped"
        invalid-feedback="Nr. pedido obrigatório."
        :state="nameState"
      >
        <b-form-input
          id="name-input"
          v-model="numped"
          :state="nameState"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Informe a linha do pedido."
        label-for="linha"
        invalid-feedback="Linha obrigatória."
        :state="nameState"
      >
        <div>
          <b-form-select v-model="linha" :options="options"></b-form-select>
        </div>
      </b-form-group>

      <!--<b-form-group
        label="Informe a quantidade de volumes."
        label-for="volume"
        invalid-feedback="Qnt volumes obrigatório."
        :state="nameState"
      >
        <b-form-input
          id="name-input"
          v-model="volume"
          :state="nameState"
          required
        ></b-form-input>
      </b-form-group>-->
    </form>

    <template #modal-footer="{}">
      <b-button size="sm" variant="success" @click="handleOk">
        Gerar Etiqueta
      </b-button>
      <!-- <b-button size="sm" variant="warning" @click="abrirModalTotalEtiquetas">
        Gerar Todas as Etiquetas
      </b-button> -->
      <b-button size="sm" variant="danger" @click="resetModal">
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import GeracaoEtiquetas from "@/services/listagem_pedidos/GeracaoEtiquetas";
export default {
  data() {
    return {
      numped: "",
      volume: "",
      nameState: null,
      array: [],
      linha: 1,
      options: [
        { value: 1, text: "Linha 1 - Paineis" },
        { value: 2, text: "Linha 2 - Cabos" },
        { value: 3, text: "Linha 3 - Diversos" },
        { value: 4, text: "Linha 4 - Perfis" },
        { value: 5, text: "Linha 5 - Inversores" },
      ],
      gerarEtiqueta: GeracaoEtiquetas.build(),
    };
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.numped = "";
      this.linha = 1;
      this.volume = "";
      this.nameState = null;
      this.$bvModal.hide("modal-etiquetas");
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.gerarEtiqueta.setNumeroPedido(this.numped);
      this.gerarEtiqueta.setVolumePedido(this.volume);
      this.gerarEtiqueta.setLinhaPedido(this.linha);
      this.gerarEtiqueta.abrirEtiqueta();
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    abrirModalTotalEtiquetas() {
      this.$bvModal.hide("modal-etiquetas");
      this.$bvModal.show("modal-total-etiquetas");
    },
  },
};
</script>
