<template>
  <div
    :style="`border: 1px solid ${borderColor} !important`"
    class="my-2"
  >
    <v-row
      v-bind="$attrs"
      align="center"
      class="font-weight-bold ma-auto table-head py-2"
    >
      <slot></slot>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "DefaultTableHead",
  props: {
    isTransportadora: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    borderColor() {
      const isDarkModeOn = this.$vuetify.theme.dark;
      return isDarkModeOn ? "#383838" : "#dee2e6";
    },
  },
};
</script>

<style scoped>
.table-head {
  background-color: #002233;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-top: 8px solid #00bfcf;
}

.border-r {
  border-right: 1px solid white;
}
</style>
