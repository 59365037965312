import env from "@/env";

export default class GeracaoEtiquetas {
  numeroPedido = 0;
  volumePedido = 0;
  linhaPedido = 0;
  vm;
  loading = false;
  static build() {
    return new GeracaoEtiquetas();
  }

  setNumeroPedido(numeroPedido) {
    this.numeroPedido = numeroPedido;
    return this;
  }
  setVolumePedido(volumePedido) {
    this.volumePedido = volumePedido;
    return this;
  }
  setLinhaPedido(linhaPedido) {
    this.linhaPedido = linhaPedido;
    return this;
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async abrirEtiqueta() {
    const url =
      env.baseUrl +
      `/pedido/volume-imp/${this.numeroPedido}/${this.linhaPedido}`;
    window.open(url);
  }

  async abrirTotalEtiqueta() {
    const url = env.baseUrl + `/pedido/volume-imp-total/${this.numeroPedido}`;
    window.open(url);
  }
}
